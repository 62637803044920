export default class CheckEmailExist {
  constructor(email, $http, $i18n) {
    this.email = email;
    this.$http = $http;
    this.$i18n = $i18n;
  }

  get isValidFunc() {
    const validationFunc = async () => {
      const { data } = await this.$http.get('/api/v2/validation/check_email_exist', {
        params: {
          email: this.email,
        },
      });
      if (!data.data.valid) {
        return this.$i18n.t('errors.loginRegister.email.exist');
      }
    };

    return validationFunc;
  }
}
