<template>
  <div class="wrapper--input">
    <p class="pt-2">
      {{ $t('home.managerSection.loginPrompt1')
      }}<a href="javascript:" @click="$modal.show('modal-login')">{{
        $t('home.managerSection.loginPrompt2')
      }}</a>
      {{ $t('home.managerSection.loginPrompt3') }}
    </p>
    <div class="form--group" :class="{ 'has-error': validation.hasError('name') }">
      <div class="left--col">
        <label for="name">
          <span>{{ $t('profile.name') }}</span>
        </label>
      </div>
      <div class="right--col">
        <input
          id="name"
          v-model="name"
          type="text"
          class="basic--input"
          :placeholder="$t('addListing.placeholder.name')"
        />
        <span class="val-error" v-if="validation.hasError('name')">{{
          validation.firstError('name')
        }}</span>
      </div>
    </div>
    <div class="form--group" :class="{ 'has-error': validation.hasError('email') }">
      <div class="left--col">
        <label for="email">
          <span>{{ $t('loginRegister.email') }}</span>
        </label>
      </div>
      <div class="right--col">
        <input
          id="email"
          v-model="email"
          type="email"
          class="basic--input"
          :placeholder="$t('addListing.placeholder.email')"
        />
        <span class="val-error" v-if="validation.hasError('email')">{{
          validation.firstError('email')
        }}</span>
      </div>
    </div>
    <div class="form--group" :class="{ 'has-error': validation.hasError('password') }">
      <div class="left--col">
        <label for="password">
          <span>{{ $t('loginRegister.pass') }}</span>
        </label>
      </div>
      <div class="right--col">
        <div class="input--group">
          <input
            id="password"
            v-model="password"
            class="basic--input password--input"
            :placeholder="$t('addListing.placeholder.pass')"
            :type="showPass ? 'text' : 'password'"
          />
          <div class="append--right" @click="() => (showPass = !showPass)">
            <v-icon v-if="showPass">mdi-eye</v-icon>
            <v-icon v-else>mdi-eye-off</v-icon>
          </div>
        </div>
        <span class="val-error" v-if="validation.hasError('password')">{{
          validation.firstError('password')
        }}</span>
      </div>
    </div>
    <div class="form--group" :class="{ 'has-error': validation.hasError('phone') }">
      <div class="left--col">
        <label for="phone">
          <span>{{ $t('profile.phone') }}</span>
        </label>
      </div>
      <div class="right--col">
        <input
          id="phone"
          v-model="phone"
          type="text"
          class="basic--input"
          :placeholder="$t('addListing.placeholder.phone')"
        />
        <span class="val-error" v-if="validation.hasError('phone')">{{
          validation.firstError('phone')
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import CheckEmailExist from '@/validations/checkEmailExist';
import { Validator } from 'simple-vue-validator';
export default {
  data() {
    return {
      showPass: false,
    };
  },
  computed: {
    name: {
      get() {
        return this.$store.state.signUp.name;
      },
      set(value) {
        this.$store.commit('signUp/SET_NAME', value);
      },
    },
    email: {
      get() {
        return this.$store.state.signUp.email;
      },
      set(value) {
        this.$store.commit('signUp/SET_EMAIL', value);
      },
    },
    password: {
      get() {
        return this.$store.state.signUp.password;
      },
      set(value) {
        this.$store.commit('signUp/SET_PASSWORD', value);
      },
    },
    phone: {
      get() {
        return this.$store.state.signUp.phone;
      },
      set(value) {
        this.$store.commit('signUp/SET_PHONE', value);
      },
    },
  },
  methods: {
    validate() {
      return this.$validate().then(
        function (success) {
          return !!success;
        }.bind(this),
      );
    },
  },
  validators: {
    name: {
      validator(value) {
        return Validator.value(value)
          .required(this.$i18n.t('errors.loginRegister.fullName.required'))
          .maxLength(255, this.$i18n.t('errors.loginRegister.fullName.max', { maxChar: 255 }));
      },
    },
    password: {
      validator(value) {
        return Validator.value(value)
          .required(this.$i18n.t('errors.loginRegister.password.required'))
          .minLength(6, this.$i18n.t('errors.loginRegister.password.min', { minChar: 6 }));
      },
    },
    phone: {
      validator(value) {
        return Validator.value(value)
          .required(this.$i18n.t('errors.phone.required'))
          .maxLength(15, this.$i18n.t('errors.phone.max', { maxChar: 15 }));
      },
    },
    email: {
      debounce: 500,
      validator: function (email_) {
        const checkEmailExist = new CheckEmailExist(email_, this.$http, this.$i18n);
        return Validator.value(email_)
          .required(this.$i18n.t('errors.loginRegister.email.required'))
          .email(this.$i18n.t('errors.loginRegister.email.required'))
          .maxLength(255, this.$i18n.t('errors.loginRegister.email.max', { maxChar: 255 }))
          .custom(checkEmailExist.isValidFunc);
      },
    },
  },
};
</script>

<style></style>
